/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line no-duplicate-imports
import {
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  Paper,
  useMediaQuery,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { getData } from "../../Services/getData";
import { LocalUrlEnum, urlEnum } from "../../Utils/utils";
import ChooseStorageAndColor from "./ChooseStorageAndColor";
import LinearProgressWithLabel from "./LinearProgressWithLabel";
import PersonalData from "./PersonalData";
import Questions from "./Questions";
import { withStyles } from "@mui/styles";
import { createStyles } from "@mui/material/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import QuestionnaireDone from "./QuestionnaireDone";
import { Vocabulary } from "../../Utils/vocabulary";
import { postData } from "../../Services/postData";
import EstimatedPrice from "./EstimatedPrice";
import { DeviceContext } from "../../Context/deviceContext";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { StepContext } from "../../Context/stepContext";

export type AnswerType = {
  questionText: string;
  answerValue: string;
  price: string;
  hardStop: boolean;
  questions: Array<any>;
};

export type AnswersType = {
  id?: string;
  color: string;
  storage: string;
  storageId: number;
  paymentType: string;
  price: number;
  answers: Array<AnswerType>;
  personalData: any;
  paymentInfo: any;
  orderNumber?: number;
};

const stateMachine = {
  colorAndStorage: "colorAndStorage",
  questions: "questions",
  personalData: "personalData",
  estimatedPrice: "estimatedPrice",
  paymentInfo: "paymentInfo",
  done: "done",
};

type DeviceQuestionnaireProps = {
  classes: any;
};

function DeviceQuestionnaire(props: DeviceQuestionnaireProps) {
  const deviceContext = useContext(DeviceContext);
  const stepContext = useContext(StepContext);
  const { classes } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const matches = useMediaQuery("(max-width: 850px)");
  const notifyError = (message: string) => toast.error(message);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(stateMachine.colorAndStorage);
  const [linearProgressValue, setLinearProgressValue] = useState(0);
  const [numberOfQuestion, setNumberOfQuestion] = useState(0);
  const [questions, setQuestions] = useState<any>([]);
  const [terms, setTerms] = useState(false);
  const [phonePrice, setPhonePrice] = useState(0);
  const [personalDataTemplateId, setPersonalDataTemplateId] = useState<
    Array<string>
  >([]);
  const [personalData, setPersonalData] = useState<any>({});
  const [paymentInfo, setPaymentInfo] = useState<any>({});
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [price, setPrice] = useState({
    consignmentPrice: 0,
    estimatedPrice: 0,
  });
  const [answers, setAnswers] = useState<AnswersType>({
    color: "",
    storageId: 0,
    paymentType: "",
    price: 0,
    storage:
      deviceContext.device && deviceContext.device.configurations[0].storage
        ? deviceContext.device.configurations[0].storage
        : "",
    answers: Array<AnswerType>(),
    personalData: {},
    paymentInfo: {},
  });

  /**
   *
   */
  useEffect(() => {
    if (deviceContext.device === "") {
      getDevice();
      stepContext.setStep(2);
    } else {
      for (let i = 0; i < deviceContext.device.configurations.length; i++) {
        if (
          deviceContext.device.configurations[i].colors.length > 1 &&
          deviceContext.device.configurations[i].colors[0].color.trim() ===
            "Orice culoare"
        ) {
          deviceContext.device.configurations[i].colors.splice(0, 1);
        }
      }
      if (questions.length === 0) {
        getQuestions();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceContext]);

  useEffect(() => {
    if (location.search !== "") {
      const search = location.search.split("?");
      search.forEach((value) => {
        if (value !== "") {
          const values = value.split("=");
          if (values[0] === "stateMachine") setStatus(values[1]);
          if (values[0] === "numberOfQuestion")
            setNumberOfQuestion(+values[1] - 1);
        }
      });
    }
  }, [location]);

  /**
   *
   */
  useEffect(() => {
    if (
      personalDataTemplateId.length !== 0 &&
      Object.keys(personalData).length === 0 &&
      Object.keys(paymentInfo).length === 0
    ) {
      getPersonalDataTemplates();
    }
  }, [personalDataTemplateId]);

  /**
   *
   * @returns
   */
  async function getDevice() {
    const url = `${urlEnum.clientQuestionnaires}/getDevice/deviceName/${
      location.pathname.split("/")[2]
    }`;
    const res = (await getData(url)) as any;
    if (res.error) {
      return;
    }
    if (res.data === "") return;
    deviceContext.setDevice(res.data);
  }

  /**
   * get questions
   */
  async function getQuestions() {
    setLoading(true);
    await getData(
      `${urlEnum.clientQuestionnaires}/questionnaire/${deviceContext.device.questionnaireId}`
    )
      .then(async (res) => {
        const values = [] as any;
        res.data[0].questions.forEach((quest: any) => {
          if (!(quest.answers.length === 1 && quest.answers[0].option === ""))
            values.push(quest);
        });
        setQuestions(values);
        setPersonalDataTemplateId(res.data[0].personalDataTemplateId);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  /**
   * get personal data templates
   */
  async function getPersonalDataTemplates() {
    personalDataTemplateId.forEach(async (id) => {
      await getData(`${urlEnum.clientQuestionnaires}/personalData/${id}`)
        .then((res) => {
          if (res.data[0].paymentInfo) {
            setPaymentInfo(res.data[0]);
          } else {
            setPersonalData(res.data[0]);
          }
        })
        .catch((err) => {
          //
        });
    });
  }

  /**
   *
   * @param storage
   * @param index
   * change storage value
   */
  function handleChangeStorage(storage: string, index: number) {
    setAnswers({
      ...answers,
      storage: storage,
      storageId: index,
      color: "",
    });
  }

  /**
   *
   * @param color
   * change color value
   */
  function handleChangeColor(color: any) {
    setAnswers({
      ...answers,
      color: color.color,
      price: color.price !== "" ? color.price : 0,
    });
    setPhonePrice(color.price !== "" ? color.price : 0);
  }

  /**
   *
   * @param answer
   *
   */
  function handleAddAnswer(answer: AnswerType) {
    const newAnswers = answers.answers.slice();
    const index = newAnswers.findIndex(
      (ans) => ans.questionText === answer.questionText
    );
    if (index >= 0) {
      newAnswers[index] = answer;
    } else {
      newAnswers.push(answer);
    }
    setAnswers({ ...answers, answers: newAnswers });
  }

  /**
   *
   * @param personalData
   * change personal data information
   */
  function handleChangePersonalData(personalData: any) {
    setAnswers({ ...answers, personalData: personalData });
  }

  /**
   *
   * @param personalData
   */
  function handleChangePaymentInfo(paymentInfo: any) {
    setAnswers({ ...answers, paymentInfo: paymentInfo });
  }
  /**
   *chnage linear progress percent
   */
  function handleChangeLinearProgressForNext() {
    setLinearProgressValue(
      linearProgressValue + Math.floor(100 / (questions.length + 4))
    );
    if (status === stateMachine.paymentInfo) {
      setLinearProgressValue(100);
    }
  }

  /**
   *
   * @returns
   * check if color and storage are selected
   */
  function validateColorAndStorage() {
    if (answers.color === "" || answers.storage === "") {
      notifyError(Vocabulary.pleaseSelectColorAndStorage);
      return false;
    }
    return true;
  }

  /**
   *
   * @returns
   * check if it's selected any answer
   */
  function validateQuestion() {
    if (
      !answers.answers[numberOfQuestion] ||
      answers.answers[numberOfQuestion].questionText === "" ||
      answers.answers[numberOfQuestion].answerValue === "" ||
      answers.answers[numberOfQuestion].price === ""
    ) {
      notifyError(Vocabulary.pleaseSelectTheAnswer);
      return false;
    }
    return true;
  }
  /**
   *
   * @returns
   * check if the information has been completed
   */
  function validatePersonalData() {
    if (!terms) {
      notifyError(Vocabulary.termsError);
      return false;
    }
    if (invalidEmail) {
      notifyError(Vocabulary.invalidEmail);
      return false;
    }
    const personalInformation = personalData.fields.filter(
      (field: any) => field.required
    );
    let values = Object.entries(answers.personalData);
    values = values.filter((value) => value[1] !== "");
    for (let i = 0; i < personalInformation.length; i++) {
      const index = values.findIndex(
        (value) => value[0] === personalInformation[i].name
      );
      if (index < 0) {
        notifyError(Vocabulary.pleaseCompletePersonalData);
        return false;
      }
    }
    setTerms(false);
    return true;
  }

  /**
   *
   * @param value
   */
  function changeInvalidEmailValue(value: boolean) {
    setInvalidEmail(value);
  }

  function handleChangeTypePaymentAndPrice(price: number, paymentType: string) {
    setAnswers({ ...answers, price: price, paymentType: paymentType });
  }

  /**
   * go next
   */
  function handleNext() {
    //color and storage
    const aTag: any = document.getElementById("questionnaire");
    if (status === stateMachine.colorAndStorage) {
      if (!validateColorAndStorage()) return;
      aTag.href = "?stateMachine=questions?numberOfQuestion=1";
      navigate("?stateMachine=questions?numberOfQuestion=1");
      handleChangeLinearProgressForNext();
      //questions
    } else if (status === stateMachine.questions) {
      if (!validateQuestion()) return;
      const newQuestions = questions.slice();
      if (answers.answers[numberOfQuestion].questions.length !== 0) {
        newQuestions.splice(
          numberOfQuestion + 1,
          0,
          ...answers.answers[numberOfQuestion].questions
        );
        setQuestions(newQuestions);
      }

      handleChangeLinearProgressForNext();
      if (numberOfQuestion === newQuestions.length - 1) {
        aTag.href = "?stateMachine=personalData";
        navigate("?stateMachine=personalData");
      } else {
        aTag.href = `?stateMachine=questions?numberOfQuestion=${
          numberOfQuestion + 2
        }`;
        navigate(
          `?stateMachine=questions?numberOfQuestion=${numberOfQuestion + 2}`
        );
      }
    }
  }

  /**
   * go back
   */
  function handleBack() {
    //questions
    if (status === stateMachine.questions) {
      if (
        answers.answers[numberOfQuestion - 1] &&
        answers.answers[numberOfQuestion - 1].questions.length !== 0
      ) {
        const newQuestions = questions.slice();
        newQuestions.splice(
          numberOfQuestion,
          answers.answers[numberOfQuestion - 1].questions.length
        );
        setQuestions(newQuestions);
      }

      if (numberOfQuestion === 0) {
        setLinearProgressValue(0);
        navigate("?stateMachine=colorAndStorage");
      } else {
        navigate(
          `?stateMachine=questions?numberOfQuestion=${numberOfQuestion}`
        );
        setLinearProgressValue(
          linearProgressValue - Math.ceil(100 / (questions.length + 4))
        );
      }
      //personalData
    } else if (status === stateMachine.personalData) {
      setLinearProgressValue(
        linearProgressValue - Math.ceil(100 / (questions.length + 4))
      );
      navigate(
        `?stateMachine=questions?numberOfQuestion=${numberOfQuestion + 1}`
      );
    } else if (status === stateMachine.estimatedPrice) {
      navigate("?stateMachine=personalData");
      setLinearProgressValue(
        linearProgressValue - Math.ceil(100 / (questions.length + 4))
      );
    } else if (status === stateMachine.paymentInfo) {
      navigate("?stateMachine=estimatedPrice");
      setLinearProgressValue(
        linearProgressValue - Math.ceil(100 / (questions.length + 4))
      );
    }
  }

  /**
   *
   * @returns
   * show each step
   */
  function showQuestion() {
    switch (status) {
      case stateMachine.colorAndStorage:
        return (
          <ChooseStorageAndColor
            device={deviceContext.device}
            answers={answers}
            handleChangeStorage={handleChangeStorage}
            handleChangeColor={handleChangeColor}
          />
        );
      case stateMachine.questions:
        return (
          <Questions
            question={questions[numberOfQuestion]}
            answers={answers.answers}
            numberOfQuestion={numberOfQuestion}
            handleAddAnswer={handleAddAnswer}
          />
        );
      case stateMachine.personalData:
        return (
          <PersonalData
            title={Vocabulary.personalData}
            device={deviceContext.device}
            personalData={personalData}
            personalInformation={answers.personalData}
            handleChangePersonalData={handleChangePersonalData}
            changeInvalidEmailValue={changeInvalidEmailValue}
            finishOrder={() => ""}
          >
            <div onClick={() => setTerms(!terms)} style={{ cursor: "pointer" }}>
              <Checkbox checked={terms} className={classes.checkbox} />
              {Vocabulary.terms}
            </div>
            <div style={{ marginBottom: 20 }}>
              <br />
              <a
                href="https://www.yzzy.ro/termeni-si-conditii/"
                target="_blank"
                rel="noreferrer"
                style={{ color: "#647273", padding: "20px 10px" }}
              >
                Termenii și condiții
              </a>
            </div>
            <br />
            <br />
            <br />
            <br />
          </PersonalData>
        );
      case stateMachine.estimatedPrice:
        return (
          <EstimatedPrice
            consignmentPrice={price.consignmentPrice}
            estimatedPrice={price.estimatedPrice}
            handleChangeTypePaymentAndPrice={handleChangeTypePaymentAndPrice}
            handleChangeLinearProgressForNext={
              handleChangeLinearProgressForNext
            }
          />
        );
      case stateMachine.paymentInfo:
        return (
          <PersonalData
            title={Vocabulary.paymentInfo}
            device={deviceContext.device}
            personalData={paymentInfo}
            personalInformation={answers.paymentInfo}
            handleChangePersonalData={handleChangePaymentInfo}
            changeInvalidEmailValue={changeInvalidEmailValue}
            finishOrder={finishOrder}
          >
            <Button
              variant="contained"
              style={{ backgroundColor: "#358800", minWidth: 120, margin: 10 }}
              onClick={finishOrder}
            >
              {Vocabulary.place}
            </Button>
          </PersonalData>
        );
      case stateMachine.done:
        return <QuestionnaireDone orderNumber={answers.orderNumber} />;
      default:
        return "";
    }
  }

  /**
   *
   * @returns
   */
  function calculatePrice() {
    let newPrice = phonePrice;
    let consignmentPrice = parseInt(phonePrice.toString());
    switch (true) {
      case phonePrice >= 500 && phonePrice < 1000:
        consignmentPrice = consignmentPrice + 100;
        break;
      case phonePrice >= 1000 && phonePrice < 2000:
        consignmentPrice = consignmentPrice + 150;
        break;
      case phonePrice >= 2000 && phonePrice < 3000:
        consignmentPrice = consignmentPrice + 200;
        break;
      case phonePrice >= 3000 && phonePrice < 4000:
        consignmentPrice = consignmentPrice + 250;
        break;
      case phonePrice >= 4000:
        consignmentPrice = consignmentPrice + 300;
        break;
      default:
        break;
    }
    answers.answers.forEach((answer) => {
      const answersPrice = answer.price.split(";");
      for (let i = 0; i < answersPrice.length; i++) {
        if (answersPrice[i] !== "") {
          const [value, type] = answersPrice[i].split(" ");
          if (!isNaN(+value)) {
            if (type === "%") {
              consignmentPrice -= (+value / 100) * consignmentPrice;
              newPrice -= (+value / 100) * newPrice;
            } else {
              newPrice -= +value;
              consignmentPrice -= +value;
            }
          }
        }
      }
    });
    if (consignmentPrice < 50) consignmentPrice = 50;
    if (newPrice < 50) newPrice = 50;
    setPrice({ consignmentPrice: consignmentPrice, estimatedPrice: newPrice });
    return newPrice;
  }
  /**
   *
   * @returns
   * save answers
   */
  function handleSubmit() {
    setLoading(true);
    if (status === stateMachine.personalData) {
      if (!validatePersonalData()) {
        setLoading(false);
        return;
      }
    }
    const price = calculatePrice();
    const emptyPaymentInfo = {} as any;
    paymentInfo.fields.forEach((pi: any) => {
      emptyPaymentInfo[pi.name] = "";
    });
    answers.price = price < 50 ? 50 : price;
    const newAnswers = {} as any;
    newAnswers.deviceDetails = {
      deviceName: deviceContext.device.deviceName,
      deviceType: deviceContext.device.deviceType,
      manufacturer: deviceContext.device.manufacturer,
      model: deviceContext.device.model,
      storage: answers.storage,
      color: answers.color,
    };
    newAnswers.paymentInfo = emptyPaymentInfo;
    newAnswers.estimatedPrice = price < 50 ? 50 : price;
    newAnswers.answers = answers.answers;
    newAnswers.personalData = answers.personalData;
    newAnswers.answers.forEach((ans: any) => {
      if (ans.hardStop) {
        newAnswers.hardStop = true;
      }
    });

    postData(urlEnum.clientQuestionnaires, newAnswers)
      .then((res) => {
        if (newAnswers.hardStop) {
          setLoading(false);
          navigate(LocalUrlEnum.hardStop);
          return;
        }
        setAnswers({
          ...answers,
          id: res.data._id,
          orderNumber: res.data.orderNumber,
        });
        handleChangeLinearProgressForNext();
        setLoading(false);
        navigate("?stateMachine=estimatedPrice");
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  /**
   *
   */
  function finishOrder() {
    if (
      Object.keys(answers.paymentInfo).length === 0 &&
      Object.keys(paymentInfo).length !== 0
    ) {
      notifyError(Vocabulary.pleaseCompletePersonalData);
      return false;
    }
    if (
      !answers.paymentInfo["Adresa de ridicare"] ||
      answers.paymentInfo["Adresa de ridicare"] === ""
    ) {
      notifyError(Vocabulary.pleaseCompletePersonalData);
      return false;
    }
    if (
      !answers.paymentInfo["Nume complet titular cont"] ||
      answers.paymentInfo["Nume complet titular cont"] === ""
    ) {
      notifyError(Vocabulary.pleaseCompletePersonalData);
      return false;
    }
    if (
      !new RegExp("^RO[0-9]{2}[A-Z]{4}[A-z0-9]{16}$").test(
        answers.paymentInfo["Nr. contul IBAN"]
      )
    ) {
      notifyError(Vocabulary.invalidIBAN);
      return false;
    }
    setLoading(true);
    const newAnswers = {} as any;
    newAnswers.paymentInfo = answers.paymentInfo;
    newAnswers.deviceDetails = {
      deviceName: deviceContext.device.deviceName,
      deviceType: deviceContext.device.deviceType,
      manufacturer: deviceContext.device.manufacturer,
      model: deviceContext.device.model,
      storage: answers.storage,
      color: answers.color,
    };
    newAnswers.estimatedPrice = answers.price;
    newAnswers.paymentType = answers.paymentType;
    newAnswers.answers = answers.answers;
    newAnswers.personalData = answers.personalData;
    newAnswers.orderNumber = answers.orderNumber;
    postData(`${urlEnum.clientQuestionnaires}/order/${answers.id}`, newAnswers)
      .then((res) => {
        handleChangeLinearProgressForNext();
        navigate("?stateMachine=done");
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  return (
    <div>
      <Helmet>
        <title>
          {Vocabulary.deviceTitle.replace(
            "{manufacturer}",
            deviceContext.device.deviceName
          )}
        </title>
        <meta
          name="title"
          content={Vocabulary.deviceTitle.replace(
            "{manufacturer}",
            deviceContext.device.deviceName
          )}
        />
        <meta
          name="description"
          content={Vocabulary.deviceDescription.replace(
            "{manufacturer}",
            deviceContext.device.deviceName
          )}
        />
      </Helmet>
      <ToastContainer />
      {status === stateMachine.estimatedPrice ||
      status === stateMachine.paymentInfo ||
      status === stateMachine.done ? (
        <div style={{ height: 50 }}></div>
      ) : (
        <>
          {" "}
          <h1
            style={{
              color: "#647273",
              margin: 30,
              fontSize: matches ? 25 : "",
            }}
          >
            {Vocabulary.chooseConfiguration} {deviceContext.device.deviceName}
          </h1>
          <p style={{ color: "#647273", marginBottom: 30, padding: "0px 5px" }}>
            {Vocabulary.estimatedPriceReminder}
          </p>
        </>
      )}
      <Paper
        id="id_paper_questionnaire"
        style={{
          borderRadius: "20px",
          border: matches ? "none" : " 0.01px solid #e4eaeb",
          boxShadow: matches ? "none" : "1px 1px 12px 5px #e9e9e9",
          width: matches ? "100%" : "70%",
          margin: "auto",
          marginTop: "15px",
          marginBottom: "90px",
        }}
      >
        <LinearProgressWithLabel value={linearProgressValue} />
        {showQuestion()}
      </Paper>
      {/* buttons */}
      {status === stateMachine.done ? (
        ""
      ) : (
        <Grid
          container
          style={{
            padding: "20px 50px",
            position: "fixed",
            bottom: 0,
            left: 0,
            zIndex: 10,
            backgroundColor: "white",
            boxShadow: "1px 1px 12px 5px #e9e9e9",
          }}
        >
          <Grid item xs={6} md={6} lg={6} textAlign="left">
            <Button
              variant="contained"
              size="large"
              style={{
                background: "linear-gradient(90deg,#cc80ff 0%,#90f 65%)",
                minWidth: 120,
              }}
              disabled={status === stateMachine.colorAndStorage}
              onClick={handleBack}
            >
              {Vocabulary.back}
            </Button>
          </Grid>
          {status === stateMachine.estimatedPrice ||
          status === stateMachine.paymentInfo ||
          status === stateMachine.done ? (
            ""
          ) : (
            <Grid item xs={6} md={6} lg={6} textAlign="right">
              <a
                id="questionnaire"
                style={{ textDecoration: "none", color: "black" }}
              >
                <Button
                  variant="contained"
                  size="large"
                  style={{
                    background:
                      "linear-gradient(90deg,rgba(145,201,85,1) 0%,rgba(81,158,0,1) 100%)",
                    minWidth: 120,
                  }}
                  onClick={(e: any) => {
                    e.preventDefault();
                    status === stateMachine.personalData
                      ? handleSubmit()
                      : handleNext();
                  }}
                >
                  {status === stateMachine.personalData
                    ? Vocabulary.offer
                    : Vocabulary.next}
                </Button>
              </a>
            </Grid>
          )}
        </Grid>
      )}
      {loading ? (
        <div className="loading">
          <CircularProgress color="primary" />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

const styles = (theme: any) =>
  createStyles({
    checkbox: {
      color: "#358800 !important",
      "&:hover": {
        backgroundColor: "transparent !important",
      },
      "&:focus": {
        backgroundColor: "transparent !important",
      },
    },
  });

export default withStyles(styles, { withTheme: true })(DeviceQuestionnaire);
